import React from "react";
import { Link } from "react-router-dom";
import projet from "../assets/undraw_projects.svg";
import competences from "../assets/undraw_skills.svg";
import experience from "../assets/undraw_experience.svg";
import cvFile from "../assets/CV_Marie-Anne_ROTH_Alternance_Dev_Full_Stack.pdf";
import { Helmet } from "react-helmet";

const Home = () => {
  return (
    <>
    <Helmet>
        <title>Marie-Anne Roth - Portfolio</title>
        <meta
          name="description"
          content="Bienvenue sur le portfolio de Marie-Anne Roth, apprenante en gestion de projets IT et développement Full Stack. Découvrez mes compétences et projets techniques."
        />
      </Helmet>
    <div className="bg-gray-100 min-h-screen flex flex-col justify-center items-center px-6">

      <div className="text-center max-w-3xl">
        <h1 className="text-4xl lg:text-6xl font-bold text-gray-800">
          Bonjour, je suis<br/><span className="text-blue-500">Marie-Anne Roth</span>
        </h1>
        <p className="mt-4 text-lg text-gray-600">
        Titulaire de 4 ans d’expériences professionnelles en gestion de projets web et en marketing digital, j’ai repris mes études à Epitech pour renforcer mes compétences en développement et en réseau.<br/>
        Je recherche une alternance en tant que <b>Développeuse Full Stack</b> pouvant débuter à partir de fin décembre 2024 pour une durée de 33 mois.
        </p>
        <div className="mt-8 flex flex-wrap justify-center gap-4">
          <a
            href={cvFile}
            download="Marie-Anne-Roth-CV.pdf"
            className="px-6 py-3 bg-blue-500 text-white rounded hover:bg-blue-600 transition"
          >
            Télécharger mon CV
          </a>
          <Link
            to="/contact"
            className="px-6 py-3 bg-gray-800 text-white rounded hover:bg-gray-900 transition"
          >
            Me contacter
          </Link>
        </div>
      </div>

      <div className="mt-16 grid grid-cols-1 lg:grid-cols-3 gap-8 text-center">
        <div className="p-6 bg-white shadow rounded-lg">
          <h2 className="text-xl font-semibold text-blue-500">Expériences</h2>
          <img
          src={experience}
          alt="undraw illustration projet"
          className="w-20 h-20 mt-6 mx-auto"
          />
          <p className="mt-4 text-gray-600">
            4 ans d’expérience en gestion de projets web et marketing digital,
            avec des compétences en SEO, WordPress, et transformation digitale.
          </p>
          <Link
            to="/experience"
            className="mt-4 inline-block text-blue-500 underline hover:text-blue-600"
          >
            En savoir plus
          </Link>
        </div>
        <div className="p-6 bg-white shadow rounded-lg">
          <h2 className="text-xl font-semibold text-blue-500">Compétences</h2>
          <img
          src={competences}
          alt="undraw illustration projet"
          className="w-20 h-20 mt-6 mx-auto"
          />
          <p className="mt-4 text-gray-600">
            Maîtrise de React, Node.js, Python, et Java pour créer des solutions
            performantes et robustes, avec une attention particulière aux détails.
          </p>
          <Link
            to="/technicalSkills"
            className="mt-4 inline-block text-blue-500 underline hover:text-blue-600"
          >
            Découvrir mes compétences
          </Link>
        </div>
        <div className="p-6 bg-white shadow rounded-lg">
          <h2 className="text-xl font-semibold text-blue-500">Projets</h2>
          <img
          src={projet}
          alt="undraw illustration projet"
          className="w-20 h-20 mt-6 mx-auto"
          />
          <p className="mt-4 text-gray-600">
            Job-board, jeux vidéo, et portfolios interactifs réalisés avec
            React, TailwindCSS, et Java.<br/><br/><br/>
          </p>
          <Link
            to="/projects"
            className="mt-4 inline-block text-blue-500 underline hover:text-blue-600"
          >
            Voir mes projets
          </Link>
        </div>
      </div>
      
    </div>
    </>
  );
};

export default Home;