import React from "react";
import { Helmet } from "react-helmet";

const Experience = () => {
  return (
    <>
    <Helmet>
        <title>Marie-Anne Roth - Expérience Professionnelles</title>
        <meta
          name="description"
          content="Découvrez mes expériences professionnelles accumulées depuis mon Bachelor en marketing digital."
        />
      </Helmet>
    <div className="bg-gray-100 min-h-screen py-10 px-6">
      <div className="max-w-5xl mx-auto">
        <h1 className="text-4xl font-bold text-gray-800 text-center">
          Mes Expériences Professionnelles
        </h1>
        <p className="mt-4 text-lg text-gray-600 text-center">
          Découvrez mon parcours professionnel, de la gestion de projets web au développement de solutions numériques.
        </p>
        <div className="mt-12">
          <div className="relative pl-6 pb-8">
            <div className="absolute -left-7 bg-blue-500 text-white w-10 h-10 flex items-center justify-center rounded-full">
              📅
            </div>
            <h2 className="text-xl font-semibold text-blue-500">Chargée de Web Marketing - Afpols</h2>
            <p className="text-gray-600 text-sm mt-1">2019 - 2024 | Paris</p>
            <p className="mt-4 text-gray-600">
              Organisme spécialisé dans les métiers du logement social. Mes responsabilités incluaient :
            </p>
            <ul className="list-disc ml-8 mt-2 text-gray-600">
              <li>Refonte du site web et intégration d'un blog et d'un espace actualités.</li>
              <li>Gestion des réseaux sociaux, campagnes emailing, et analyse SEO/SEA.</li>
              <li>Implémentation de Salesforce pour accompagner la transformation digitale.</li>
            </ul>
            <p className="mt-2 text-gray-600">
              <span className="font-semibold">Technologies utilisées :</span> Salesforce, HTML/CSS, suite Google, suite Adobe.
            </p>
          </div>

          <div className="relative pl-6 pb-8">
            <div className="absolute -left-7 bg-blue-500 text-white w-10 h-10 flex items-center justify-center rounded-full">
              📅
            </div>
            <h2 className="text-xl font-semibold text-blue-500">Chargée de Web Marketing - MyBizDev</h2>
            <p className="text-gray-600 text-sm mt-1">2019 | Paris</p>
            <p className="mt-4 text-gray-600">
              Start-up spécialisée dans la mise en relation de commerciaux freelance avec des entreprises. Mes missions :
            </p>
            <ul className="list-disc ml-8 mt-2 text-gray-600">
              <li>Développement de la stratégie de contenu et optimisation SEO avec SEMRUSH.</li>
              <li>Création de supports de communication en HTML/CSS.</li>
            </ul>
            <p className="mt-2 text-gray-600">
              <span className="font-semibold">Technologies utilisées :</span> SEMRUSH, HTML/CSS, suite Google, Hubspot.
            </p>
          </div>

          <div className="relative pl-6 pb-8">
            <div className="absolute -left-7 bg-blue-500 text-white w-10 h-10 flex items-center justify-center rounded-full">
              📅
            </div>
            <h2 className="text-xl font-semibold text-blue-500">Community Manager - Ghost Frog</h2>
            <p className="text-gray-600 text-sm mt-1">2018 | Montreuil</p>
            <p className="mt-4 text-gray-600">
              Start-up de création de jeux phygitaux. Mes responsabilités incluaient :
            </p>
            <ul className="list-disc ml-8 mt-2 text-gray-600">
              <li>Gestion des réseaux sociaux et rédaction de contenus en anglais.</li>
              <li>Création de visuels avec la suite Adobe.</li>
            </ul>
            <p className="mt-2 text-gray-600">
              <span className="font-semibold">Technologies utilisées :</span> HTML/CSS, suite Adobe, réseaux sociaux (Medium, Discord).
            </p>
          </div>
        </div>
        <div className="mt-12 text-center">
          <p className="text-gray-600">
            Envie d'en savoir plus sur mes compétences et projets ?
          </p>
          <a
            href="/projects"
            className="mt-4 inline-block px-6 py-3 bg-blue-500 text-white rounded hover:bg-blue-600 transition"
          >
            Voir mes projets
          </a>
        </div>
      </div>
    </div>
    </>
  );
};

export default Experience;
