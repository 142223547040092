import React, { useState } from "react";
import { Link } from "react-router-dom";
import githubIcon from "../assets/github-white.svg";
import linkedinIcon from "../assets/linkedin-white.svg";
import photo from "../assets/photo.png";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <aside className="hidden lg:flex flex-col fixed top-0 left-0 w-64 h-screen bg-gray-900 text-white shadow-md z-50">
        <div className="flex flex-col items-center py-8">
          <img
            src={photo}
            alt="Marie-Anne Roth"
            className="w-20 h-20 rounded-full mx-auto shadow-lg"
          />
          <h1 className="text-2xl font-bold text-center mt-3">
            Marie-Anne Roth
          </h1>
          <p className="text-xs font-bold text-center mt-3">
            Apprentie Gestionnaire de projets IT
            <br />
            & Développement full stack
          </p>
          <p className="mt-4"><hr className="w-8 h-1 bg-gray-200 border-0 rounded dark:bg-gray-700"/></p>
          <nav className="mt-4 space-y-4 text-center">
            <Link to="/" className="block hover:text-blue-400">Accueil</Link>
            <Link to="/about" className="block hover:text-blue-400">À propos</Link>
            <Link to="/technicalSkills" className="block hover:text-blue-400">Compétences Techniques</Link>
            <Link to="/managementSkills" className="block hover:text-blue-400">Compétences en Gestion</Link>
            <Link to="/projects" className="block hover:text-blue-400">Projets</Link>
            <Link to="/experience" className="block hover:text-blue-400">Expérience</Link>
            <Link to="/contact" className="block hover:text-blue-400">Contact</Link>
          </nav>

          {/* Social Links */}
          <div className="mt-4 flex space-x-6">
            <a
              href="https://github.com/Marie-AnneR"
              target="_blank"
              rel="noopener noreferrer"
              className="w-8 h-8"
            >
              <img src={githubIcon} alt="GitHub" className="w-full h-full" />
            </a>
            <a
              href="https://www.linkedin.com/in/marie-anne-roth/"
              target="_blank"
              rel="noopener noreferrer"
              className="w-8 h-8"
            >
              <img src={linkedinIcon} alt="LinkedIn" className="w-full h-full" />
            </a>
          </div>
        </div>
      </aside>

      {/* Mobile Burger Button */}
      <button
        className="lg:hidden fixed top-4 left-4 z-50 text-blue-400 focus:outline-none"
        onClick={toggleMenu}
      >
        {isOpen ? "✕" : "☰"}
      </button>

      {/* Mobile Sidebar */}
      <div
        className={`fixed top-0 left-0 h-full w-64 bg-gray-900 text-white shadow-md transform ${
          isOpen ? "translate-x-0" : "-translate-x-full"
        } transition-transform duration-300 ease-in-out z-40`}
      >
        <div className="flex flex-col items-center py-8">
          <nav className="space-y-6 text-center">
            <Link
              to="/"
              className="block hover:text-blue-400"
              onClick={toggleMenu}
            >
              Accueil
            </Link>
            <Link
              to="/about"
              className="block hover:text-blue-400"
              onClick={toggleMenu}
            >
              À propos
            </Link>
            <Link
              to="/technicalSkills"
              className="block hover:text-blue-400"
              onClick={toggleMenu}
            >
              Compétences Techniques
            </Link>
            <Link
              to="/managementSkills"
              className="block hover:text-blue-400"
              onClick={toggleMenu}
            >
              Compétences en Gestion
            </Link>
            <Link
              to="/projects"
              className="block hover:text-blue-400"
              onClick={toggleMenu}
            >
              Projets
            </Link>
            <Link
              to="/experience"
              className="block hover:text-blue-400"
              onClick={toggleMenu}
            >
              Expérience
            </Link>
            <Link
              to="/contact"
              className="block hover:text-blue-400"
              onClick={toggleMenu}
            >
              Contact
            </Link>
            <div className="mt-8 flex space-x-6 flex flex-row justify-center ">
            <a
              href="https://github.com/Marie-AnneR"
              target="_blank"
              rel="noopener noreferrer"
              className="w-8 h-8"
            >
              <img src={githubIcon} alt="GitHub" className="w-full h-full" />
            </a>
            <a
              href="https://www.linkedin.com/in/marie-anne-roth/"
              target="_blank"
              rel="noopener noreferrer"
              className="w-8 h-8"
            >
              <img src={linkedinIcon} alt="LinkedIn" className="w-full h-full" />
            </a>
          </div>
          </nav>
        </div>
      </div>

      {/* Overlay to close the menu */}
      {isOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 z-30"
          onClick={toggleMenu}
        ></div>
      )}
    </>
  );
};

export default Navbar;
