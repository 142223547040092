import React from "react";
import lumaProject from "../assets/LumaProject.png";
import portfolioProject from "../assets/PortfolioProject.png";
import hangmanProject from "../assets/HangmanProject.png";
import javaProject from "../assets/javaProject.png";
import { Helmet } from "react-helmet";

const Projects = () => {
  return (
    <>
    <Helmet>
        <title>Marie-Anne Roth - Projets académiques</title>
        <meta
          name="description"
          content="Une liste de mes projets techniques réalisées depuis mon entrée à Epitech."
        />
      </Helmet>
    <div className="bg-gray-100 min-h-screen py-10 px-6">
      <div className="max-w-6xl mx-auto">
        <h1 className="text-4xl font-bold text-gray-800 text-center">
          Mes Projets
        </h1>
        <p className="mt-4 text-lg text-gray-600 text-center">
          Voici un aperçu des projets que j'ai réalisés à Epitech depuis Septembre 2024 en utilisant différentes technologies.
        </p>

        <div className="mt-16 grid grid-cols-1 lg:grid-cols-2 gap-8 text-center">
          <div className="p-6 bg-white shadow rounded-lg">
            <h2 className="text-xl font-semibold text-blue-500">Job Board</h2>
              <img
                src={lumaProject}
                alt="undraw illustration projet"
                className="w-40 h-40 mt-6 mx-auto"
              />
              <p className="mt-4 text-gray-600">
              Développement d'une plateforme de gestion d'offres d'emploi et de candidatures en React et Node.js.
              </p>
              <p className="mt-4 text-gray-600">
              <b>Technologies</b> : React, Node.js, MySQL, TailwindCSS
              </p>
          </div> 
          <div className="p-6 bg-white shadow rounded-lg">
            <h2 className="text-xl font-semibold text-blue-500">Portfolio en HTML / CSS</h2>
              <img
                src={portfolioProject}
                alt="undraw illustration projet"
                className="w-40 h-40 mt-6 mx-auto"
              />
              <p className="mt-4 text-gray-600">
              Site vitrine responsive, hébergé sur GitHub Pages, présentant mes projets et compétences.
              </p>
              <p className="mt-4 text-gray-600">
              <b>Technologies</b> : HTML, CSS
              </p>
              <p>
                <a 
                  href="https://marie-anner.github.io/portfolio/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="mt-4 inline-block bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition"
                >
                  Voir le projet
                </a>
              </p>
          </div> 
        </div>
        <div className="mt-8 grid grid-cols-1 lg:grid-cols-2 gap-8 text-center">
          <div className="p-6 bg-white shadow rounded-lg">
            <h2 className="text-xl font-semibold text-blue-500">Jeu du Pendu</h2>
              <img
                src={hangmanProject}
                alt="undraw illustration projet"
                className="h-40 mt-6 mx-auto"
              />
              <p className="mt-4 text-gray-600">
              Création d'un jeu interactif avec interface graphique et gestion des événements utilisateurs.
              </p>
              <p className="mt-4 text-gray-600">
              <b>Technologies</b> : Python, Pygame
              </p>
          </div> 
          <div className="p-6 bg-white shadow rounded-lg">
            <h2 className="text-xl font-semibold text-blue-500">Jeu en Java</h2>
              <img
                src={javaProject}
                alt="undraw illustration projet"
                className="h-40 mt-6 mx-auto"
              />
              <p className="mt-4 text-gray-600">
                Jeu de survie et 'shoot 'em up' réalisé en Java avec LibGDX.
              </p>
              <p className="mt-4 text-gray-600">
              <b>Technologies</b> : Java, LibGDX, Tiled
              </p>
              <p>
                <a 
                  href="https://sidiah.itch.io/pokemonsurvival"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="mt-4 inline-block bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition"
                >
                  Voir le projet
                </a>
              </p>
          </div> 
        </div>
      </div>
    </div>
    </>
  );
};

export default Projects;