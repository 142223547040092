import React, {  } from "react";
import { Helmet } from "react-helmet";
 const Contact = () => {
  return (
    <>
    <Helmet>
        <title>Marie-Anne Roth - Contact</title>
        <meta
          name="description"
          content="Si mon profil vous intéresse, n'hésitez pas à me contacter."
        />
      </Helmet>
    <div className="bg-gray-100 min-h-screen py-10 px-6">
      <div className="max-w-4xl mx-auto">
        <h1 className="text-4xl font-bold text-gray-800 text-center">
          ✉️ Me Contacter
        </h1>
        <p className="mt-4 text-lg text-gray-600 text-center">
          Si mon profil vous intéresse, n'hésitez pas à me contacter !
        </p>
        <form
          className="mt-12 bg-white p-6 rounded-lg shadow-lg"
          action="https://api.web3forms.com/submit" 
          method="post"
        >
          <div className="mb-6">
            <input type="hidden" name="access_key" value="89441aa8-dc4d-4a8b-9417-52a49e7d4ede"/>
            <label htmlFor="name" className="block text-gray-700 font-semibold">
              Nom
            </label>
            <input
              type="text"
              id="name"
              name="name"
              required
              className="w-full px-4 py-2 mt-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Votre nom"
            />
          </div>

          <div className="mb-6">
            <label htmlFor="email" className="block text-gray-700 font-semibold">
              Email
            </label>
            <input
              type="email"
              id="email"
              name="email"
              required
              className="w-full px-4 py-2 mt-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Votre email"
            />
          </div>

          <div className="mb-6">
            <label
              htmlFor="message"
              className="block text-gray-700 font-semibold"
            >
              Message
            </label>
            <textarea
              id="message"
              name="message"
              required
              className="w-full px-4 py-2 mt-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              rows="6"
              placeholder="Votre message"
            />
          </div>
          <label for="submit" class="submit">
            <button 
              id="submit"
              type="submit"
              className="w-full px-6 py-3 bg-blue-500 text-white font-semibold rounded hover:bg-blue-600 transition"
            >
              Envoyer
            </button>
          </label>
        </form>
        <div className="mt-12 text-center">
          <p className="text-gray-600">Ou retrouvez-moi sur :</p>
          <div className="flex justify-center gap-6 mt-4">
            <a
              href="https://www.linkedin.com/in/marie-anne-roth/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-500 hover:text-blue-600 transition"
            >
              LinkedIn
            </a>
            <a
              href="https://github.com/Marie-AnneR/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-gray-800 hover:text-gray-900 transition"
            >
              GitHub
            </a>
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default Contact;