import React from "react";
import notionLogo from "../assets/logos/notion-logo.svg";
import trelloLogo from "../assets/logos/trello-logo.svg";
import mondayLogo from "../assets/logos/monday-logo.svg";
import githubLogo from "../assets/logos/github-logo.svg";
import brandingLogo from "../assets/logos/branding-logo.svg";
import seoLogo from "../assets/logos/seo-logo.svg";
import socialLogo from "../assets/logos/social-logo.svg";
import budjetLogo from "../assets/logos/budjet-logo.svg";
import adsLogo from "../assets/logos/ads-logo.svg";
import mockupLogo from "../assets/logos/mockup-logo.svg";
import pagesLogo from "../assets/logos/pages-logo.svg";
import uxLogo from "../assets/logos/ux-logo.svg";
import responsiveLogo from "../assets/logos/responsive-logo.svg";
import { Helmet } from "react-helmet";

const ManagementSkills = () => {
  const skills = {
    "Gestion de Projet": [
      { name: "Notion", logo: notionLogo, status: "en cours" },
      { name: "Monday", logo: mondayLogo, status: "acquis" },
      { name: "Trello", logo: trelloLogo, status: "acquis" },
      { name: "GitHub", logo: githubLogo, status: "en cours" },
    ],
    "Compétences Transverses": [
      { name: "Branding", logo: brandingLogo, status: "acquis" },
      { name: "SEO", logo: seoLogo, status: "acquis" },
      { name: "social media", logo: socialLogo, status: "acquis" },
      { name: "Budgétisation", logo: budjetLogo, status: "acquis" },
      { name: "Publicité en ligne / SEA", logo: adsLogo, status: "acquis" },
      { name: "Refonte de site", logo: pagesLogo, status: "acquis" },
      { name: "Design de Maquettes", logo: mockupLogo, status: "acquis" },
      { name: "UX Design", logo: uxLogo, status: "acquis" },
      { name: "Responsive web", logo: responsiveLogo, status: "acquis" },
    ],
  };

  const getColor = (status) => {
    switch (status) {
      case "acquis":
        return " ";
      case "en cours":
        return "bg-blue-100 text-blue-700";
      case "à apprendre":
        return "bg-gray-100 text-gray-700";
      default:
        return "";
    }
  };

  return (
    <>
    <Helmet>
        <title>Marie-Anne Roth - Compétences en gestion de projet</title>
        <meta
          name="description"
          content="Retrouvez ici toutes mes compétences reliées à la gestion de projet digitaux et en marketing digital et communication."
        />
      </Helmet>
    <div className="bg-gray-100 min-h-screen py-10 px-6">
      <div className="max-w-6xl mx-auto">
      <h1 className="text-4xl font-bold text-gray-800 text-center">
          Compétences en Gestion de Projet
        </h1>
        <p className="mt-4 text-lg text-gray-600 text-center">
          Découvrez mes compétences en gestion de projet et outils associés.
        </p>
        <div className="mt-12 space-y-12">
          {Object.entries(skills).map(([category, items]) => (
            <div key={category}>
              <h2 className="text-2xl font-bold text-blue-500">{category}</h2>
              <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-4 mt-6">
                {items.map((skill) => (
                  <div
                    key={skill.name}
                    className={`p-4 rounded-lg shadow flex flex-col items-center ${
                      getColor(skill.status)
                    }`}
                  >
                    <img
                      src={skill.logo}
                      alt={skill.name}
                      className="w-12 h-12 mb-2 object-contain"
                    />

                    <p className="font-semibold">{skill.name}</p>
                    <span className="text-sm">
                      {skill.status === "acquis"
                        ? "✅ Acquis"
                        : skill.status === "en cours"
                        ? "📘 En cours"
                        : "📖 À apprendre"}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
    </>
  );
};

export default ManagementSkills;