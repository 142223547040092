import React from "react";
import htmlLogo from "../assets/logos/html-logo.svg";
import cssLogo from "../assets/logos/css-logo.svg";
import neo4jLogo from "../assets/logos/neo4j-logo.png";
import javascriptLogo from "../assets/logos/javascript-logo.svg";
import reactLogo from "../assets/logos/react-logo.svg";
import bootstrapLogo from "../assets/logos/bootstrap-logo.svg";
import tailwindLogo from "../assets/logos/tailwindcss-logo.svg";
import vuejsLogo from "../assets/logos/vuejs-logo.svg";
import angularLogo from "../assets/logos/angular-logo.svg";
import nodejsLogo from "../assets/logos/nodejs-logo.svg";
import phpLogo from "../assets/logos/php-logo.svg";
import javaLogo from "../assets/logos/java-logo.svg";
import pythonLogo from "../assets/logos/python-logo.svg";
import rubyLogo from "../assets/logos/ruby-logo.svg";
import aspnetLogo from "../assets/logos/aspnet-logo.svg";
import PostgreSQLLogo from "../assets/logos/postgre-logo.svg";
import mongoDBLogo from "../assets/logos/mongodb-logo.svg";
import mySQLLogo from "../assets/logos/mysql-logo.svg";
import { Helmet } from "react-helmet";


const Skills = () => {
  const skills = {
    "Front-end": [
      { name: "HTML", logo: htmlLogo, status: "acquis" },
      { name: "CSS", logo: cssLogo, status: "acquis" },
      { name: "JavaScript", logo: javascriptLogo, status: "en cours" },
      { name: "React", logo: reactLogo, status: "acquis" },
      { name: "Bootstrap", logo: bootstrapLogo, status: "en cours" },
      { name: "TailwindCSS", logo:tailwindLogo, status: "acquis" },
      { name: "Vue.js", logo: vuejsLogo, status: "à apprendre" },
      { name: "Angular", logo: angularLogo, status: "à apprendre" },
    ],
    "Back-end": [
      { name: "Node.js", logo: nodejsLogo, status: "en cours" },
      { name: "PHP", logo: phpLogo, status: "en cours" },
      { name: "Java", logo: javaLogo, status: "en cours" },
      { name: "Python", logo: pythonLogo, status: "en cours" },
      { name: "Ruby", logo: rubyLogo, status: "à apprendre" },
      { name: "ASP.NET", logo: aspnetLogo, status: "à apprendre" },
    ],
    "Database": [
      { name: "MySQL", logo: mySQLLogo, status: "acquis" },
      { name: "PostgreSQL", logo: PostgreSQLLogo, status: "en cours" },
      { name: "MongoDB", logo: mongoDBLogo, status: "en cours" },
      { name: "Neo4j", logo: neo4jLogo, status: "à apprendre" },
    ],
  };

  const getColor = (status) => {
    switch (status) {
      case "acquis":
        return " ";
      case "en cours":
        return "bg-blue-100 text-blue-700";
      case "à apprendre":
        return "bg-gray-100 text-gray-700";
      default:
        return "";
    }
  };

  return (
    <>
    <Helmet>
        <title>Marie-Anne Roth - Compétences techniques</title>
        <meta
          name="description"
          content="Toutes les compétences techniques liées au développement full stack que j'ai acquis ou souhaite acquérir durant ma formation à Epitech."
        />
      </Helmet>

    <div className="bg-gray-100 min-h-screen py-10 px-6">
      <div className="max-w-6xl mx-auto">
        <h1 className="text-4xl font-bold text-gray-800 text-center">
          Mes Compétences
        </h1>
        <p className="mt-4 text-lg text-gray-600 text-center">
          Voici mes compétences actuelles, celles que je suis en train
          d'apprendre, et celles que je souhaite développer dans le futur.
        </p>
        <div className="mt-12 space-y-12">
          {Object.entries(skills).map(([category, items]) => (
            <div key={category}>
              <h2 className="text-2xl font-bold text-blue-500">{category}</h2>
              <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-4 mt-6">
                {items.map((skill) => (
                  <div
                    key={skill.name}
                    className={`p-4 rounded-lg shadow flex flex-col items-center ${
                      getColor(skill.status)
                    }`}
                  >
                    <img
                      src={skill.logo}
                      alt={skill.name}
                      className="w-12 h-12 mb-2 object-contain"
                    />

                    <p className="font-semibold">{skill.name}</p>
                    <span className="text-sm">
                      {skill.status === "acquis"
                        ? "✅ Acquis"
                        : skill.status === "en cours"
                        ? "📘 En cours"
                        : "📖 À apprendre"}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
    </>
  );
};

export default Skills;